@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "./variables";

// initial-variables.sass
$red: $redKoosmik;
$green: $greenKoosmik;

// derived-variables.sass
$primary: $greenKoosmik;
$primary-invert: findColorInvert($greenKoosmik);

// modal.sass
$modal-card-head-background-color: $blueKoosmik;
$modal-card-title-color: $white;

// table.sass
$table-row-hover-background-color: hsla(210, 50%, 35%, 0.30);

// navbar.sass
$navbar-background-color: $blueKoosmik;
$navbar-item-color: $white;
$navbar-item-active-color: $greenKoosmik;
$navbar-item-hover-color: $green;


@import "~bulma/bulma.sass";

// @see https://github.com/jgthms/bulma/pull/2519
// to remove when it's merged
button.navbar-burger {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;

  &:not(:hover) {
    background-color: inherit;
  }

  &:hover {
    border: none;
  }

  &:focus {
    outline: none
  }
}

button.dropdown-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none
  }
}

.modal-card-foot {
  .button.no-background {
    color: $white;
  }
}
