@import "~bulma/sass/utilities/mixins";

// allow load indicator for div
div.is-loading {
  position: relative;
  pointer-events: none;
  opacity: 0.5;

  &:after {
    @include loader;
    position: absolute;
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-width: 0.25em;
  }
}

// set element at bottom
.is-vflex-end {
  align-items: flex-end;
}

// remove background from button
.button.no-background {
  background-color: transparent;
  border: none;
}

// set element position fixed
.dropdown-content.is-fixed {
  position: fixed;
  top: 1rem;
  max-height: calc(100% - 2rem);
  overflow: hidden;
  overflow-y: auto;
  max-width: 100%;
}

.btn-modal {
  border: none;
  width: 18rem;
  height: 3.8rem;
  font-size: 1rem;
  background-color: $greenKoosmik;
  color: #ffffff;
}
.has-margin {
  margin: 0.3rem;
}

