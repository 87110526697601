@import "./variables";
@import "~bulma/sass/utilities/mixins";

#login {
  background: linear-gradient(225deg, $blueKoosmik 45%, $greenKoosmik) $blueKoosmik;

  .particles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .primary-title {
    color: $blueKoosmik;
  }

  .card {
    width: 25rem;
  }
}

#login-second-factor-auth {
  #code {
    font-size: 2rem;
    text-align: center;
  }
}
