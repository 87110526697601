// Koosmik colors
$blueKoosmik: #2C5885;
$redKoosmik: #C03944;
$greenKoosmik: #00AB77;
$greyBlueKoosmik: #E6EDF4;
$dark30Koosmik: #95A7B9;
$dark70Koosmik: #586675;
$dark100Koosmik: #38444F;
$whiteKoosmik: #FFFFFF;
$white70Koosmik: #CBDAE9;
