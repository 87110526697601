#dashboard {
  .card {
    margin-bottom: 1rem;
    box-shadow: none;

    .money {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0.75rem;
    }
  }
  .card-header-title {
    color: $dark70Koosmik;
    background-color: #F6F7F9;
  }

  .card-header {
   background-color: #eaebed;
  }

  .money {
    color: $dark70Koosmik;
    font-weight: 400;
  }

  .time-row {
    color: $dark30Koosmik;
    font-style: normal;
  }

  h3 {
    font-weight: bold;
  }

  .money-operation-group .list {
    padding: 0;

    & > .money-operation-row {
      padding: 1rem;
      border-top: 1px solid $white70Koosmik;
    }
    & > .money-operation-row:first-child {
        border-top: 0 solid black;
    }

  }
}
